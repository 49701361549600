import React, { Component } from 'react';

export default class Skills extends Component {
    render() {
        return (
            <div>
                <div className='card'>
                    <div className='card-content'>
                        <h6><strong>PROFESSIONAL SKILLS</strong></h6>
                        <div className='row mt-top'>
                            <div className='col s6'>
                                <p>HTML5</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '100%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>CSS3</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '100%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>JavaScript</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '90%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>JQuery</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '100%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Bootstrap</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '100%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Photoshop</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '70%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>PHP</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '85%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>SQL</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '70%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Laravel Framework</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '70%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Microsoft Office</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '100%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>React</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '70%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>API</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '70%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Git</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Social Media</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Public Relations</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Fast Learner</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Ability to Work in a Team</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Self-Motivated</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Communication Skills</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Effective Time Management</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Always on time</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Passion For Coding</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                            <div className='col s6'>
                                <p>Target Oriented</p>
                                <div className='progress grey lighten-1'>
                                    <div className='determinate blue' style={{ width: '95%' }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

