export const MenuItems = [

  {
    title: 'Home',
    url: '/',
    cName: 'nav-links',
    iconCName: 'fas fa-home'
  },


  {
    title: 'Skills',
    url: '/skills',
    cName: 'nav-links',
    iconCName: 'fas fa-copy'

  },
  {
    title: 'Educations',
    url: '/educations',
    cName: 'nav-links',
    iconCName: 'fas fa-id-badge'

  },

  {
    title: 'Experiences',
    url: '/experiences',
    cName: 'nav-links',
    iconCName: 'fas fa-graduation-cap'

  },


  {
    title: 'Projects',
    url: '/projects',
    cName: 'nav-links',
    iconCName: 'fas fa-tasks'

  }







]